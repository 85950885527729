import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 342,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  outline: "none",
  borderRadius: "8px",
};

const DetailBox = ({ label, value, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography fontSize="14px" fontFamily="myThirdFont" color="#777777">
      {label}
    </Typography>
    <Typography
      fontSize="16px"
      fontFamily="mySecondFont"
      color="#2F2F2F"
      sx={{ display: "flex", alignItems: "center" }}
    >
      {children || value}
    </Typography>
  </Box>
);

const formatDuration = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diffMs = endDate - startDate;

  const minutes = Math.floor(diffMs / 60000);
  const seconds = Math.floor((diffMs % 60000) / 1000);

  return `${minutes}min ${seconds}sec`;
};

const formatDateTimeRange = (startDateTime, endDateTime) => {
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);

  const day = String(startDate.getDate()).padStart(2, "0");
  const month = String(startDate.getMonth() + 1).padStart(2, "0");
  const year = String(startDate.getFullYear()).slice(-2);

  const formatTime12Hour = (date) => {
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
    return `${hours}:${minutes} ${ampm}`;
  };

  const startTime = formatTime12Hour(startDate);
  const endTime = formatTime12Hour(endDate);

  return `${day}/${month}/${year}, ${startTime} - ${endTime}`;
};

const SessionDetailsModal = ({ open, handleClose, allData }) => {
  const session = allData?.outstandingSessions[0];
  const ocpiSession = session?.ocpiSession;

  const duration = formatDuration(
    ocpiSession?.start_date_time,
    ocpiSession?.end_date_time
  );
  const timeRange = formatDateTimeRange(
    ocpiSession?.start_date_time,
    ocpiSession?.end_date_time
  );

  const downloadInvoice = (invoiceUrl) => {
    if (invoiceUrl) {
      window.open(invoiceUrl, "_blank");
    } else {
      alert("Invoice URL not found.");
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* top - heading */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span className="material-symbols-outlined">info</span>
              <Typography
                fontSize={"18px"}
                color="#292D33"
                fontFamily={"mySecondFont"}
              >
                Session Details
              </Typography>
            </Box>
            <Box onClick={handleClose}>
              <span
                style={{ fontSize: "32px", fontWeight: "300", color: "#222" }}
                className="material-symbols-outlined"
              >
                cancel
              </span>
            </Box>
          </Box>

          {/* Display Time Range */}
          <Box
            sx={{
              padding: "4px 12px",
              borderRadius: "16px",
              background: "#E9E9E9",
              width: "208px",
              marginTop: "16px",
              marginBottom: "12px",
            }}
          >
            <Typography fontFamily={"mySecondFont"}>{timeRange}</Typography>
          </Box>

          {/* cards */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Box
              sx={{
                width: "50%",
                backgroundImage: "url('/images/sessionenergy.png')",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "12px",
              }}
            >
              <Typography
                sx={{
                  color: "#FF7B52",
                  fontSize: "20px",
                  fontFamily: "mySecondFont",
                  display: "flex",
                  alignItems: "start",
                  gap: "4px",
                }}
              >
                {allData?.outstandingSessions[0]?.ocpiSession?.kwh}
                <span
                  style={{
                    color: "#E50000",
                    fontFamily: "mySecondFont",
                    textTransform: "none",
                  }}
                >
                  kWh
                </span>
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#2f2f2f",
                  fontFamily: "myThirdFont",
                  textAlign: "start",
                  lineHeight: "1.2",
                }}
              >
                Energy consumed
              </Typography>
            </Box>
            <Box
              sx={{
                width: "50%",
                backgroundImage: "url('/images/sessionamount.png')",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                padding: "12px",
              }}
            >
              <Typography
                sx={{
                  color: "#00733D",
                  fontSize: "20px",
                  fontFamily: "mySecondFont",
                  display: "flex",
                  alignItems: "start",
                  gap: "4px",
                }}
              >
                ₹
                {
                  allData?.outstandingSessions[0]?.ocpiSession?.total_cost
                    ?.incl_vat
                }
              </Typography>

              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#2f2f2f",
                  fontFamily: "myThirdFont",
                  textAlign: "start",
                  lineHeight: "1.2",
                }}
              >
                Amount charged
              </Typography>
            </Box>
          </Box>

          {/* details */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              mt: "16px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <DetailBox
                label="Session ID"
                value={allData?.outstandingSessions[0]?.ocpiSession?.id}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <DetailBox
                label="Start time"
                value={timeRange.split(", ")[1].split(" - ")[0]}
              />
              <DetailBox
                label="Stop time"
                value={timeRange.split(", ")[1].split(" - ")[1]}
              />
              <DetailBox label="Duration" value={duration} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <DetailBox
                label="QR CODE ID"
                value={allData?.outstandingSessions[0]?.ocpiSession?.evse_uid}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <DetailBox
                label="Charger type"
                value={
                  allData?.outstandingSessions[0]?.chargingPoint
                    ?.chargingPointType
                }
              />
              <DetailBox
                label="Connector type"
                value={
                  allData?.outstandingSessions[0]?.chargingPoint?.connectorType
                }
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <DetailBox
                label="Supply"
                value={allData?.outstandingSessions[0]?.chargingPoint?.power}
              />
              <DetailBox
                label="Unit Cost"
                value={`₹${allData?.outstandingSessions[0]?.chargingPoint?.unitCost}`}
              />
              <DetailBox
                label="Entered amount"
                value={`₹${allData?.outstandingSessions[0]?.enteredAmount}`}
              />
            </Box>
          </Box>

          {/* buttons */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              mt: "16px",
            }}
          >
            <Box sx={{ width: "40%" }}>
              <Button
                sx={{
                  color: "#E50000",
                  fontSize: "14px",
                  fontFamily: "mySecondFont",
                }}
                onClick={() => {
                  const invoiceUrl =
                    allData?.outstandingSessions[0]?.invoiceUrl; // Fetch URL dynamically
                  downloadInvoice(invoiceUrl); // Call the reusable function
                }}
              >
                <span
                  style={{ mr: "4px" }}
                  className="material-symbols-outlined"
                >
                  download
                </span>
                Invoice
              </Button>
            </Box>
            <Box sx={{ width: "60%" }}>
              <Button
                sx={{
                  padding: "8px 16px",
                  borderRadius: "8px",
                  border: "1px solid #C1C1C1",
                  width: "100%",
                  fontFamily: "mySecondFont",
                  color: "#222",
                }}
                onClick={handleClose}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SessionDetailsModal;
