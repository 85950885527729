import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { makePaymentV2 } from "../../redux/action/directPaymentSlice";
import { toast } from "sonner";
import { endPoints } from "../../API/endpoints";
import { useLocation, useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: 3,
};

const DueAmountModal = ({
  open,
  handleClose,
  amount,
  deviceId,
  outStandingAmount,
  countryCode,
  setRetryLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = React.useState(false);

  const makePaymentV2Response = useSelector(
    (state) => state.directPayment.makePaymentV2Response
  );

  const queryParams = new URLSearchParams(location.search);
  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  // Extract country code from pathname
  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2);

  const totalamount = amount + outStandingAmount;

  const handlePayment = () => {
    const newAmount = amount; // Modify the amount as needed
    setLoading(true); // Indicate that the payment process is starting

    dispatch(makePaymentV2({ amount: newAmount, deviceId }))
      .then((res) => {
        if (res.error) {
          toast.error("Payment retry failed. Please try again.");
          setLoading(false);
        } else {
          toast.success("Redirecting to Paytm...");

          const response = res.payload;

          if (response?.orderId) {
            // Load Paytm Checkout script
            const script = document.createElement("script");
            script.src = `${endPoints.paytmGatewayV2}/${response.mid}.js`;
            script.type = "application/javascript";
            script.crossOrigin = "anonymous";

            script.onload = () => {
              if (window.Paytm && window.Paytm.CheckoutJS) {
                const config = {
                  root: "",
                  flow: "DEFAULT",
                  merchant: { redirect: false },
                  data: {
                    orderId: response.orderId,
                    token: response.txnToken,
                    tokenType: "TXN_TOKEN",
                    amount: response.txnAmount.value,
                  },
                  handler: {
                    transactionStatus: function (data) {
                      console.log("data?.STATUS", data?.STATUS);

                      if (data?.STATUS === "TXN_SUCCESS") {
                        const path = ioclCustomerPath
                          ? `/iocl-charging-start/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
                          : `/iocl-charging-start/${countryCode}/${deviceId}`;
                        navigate(path);
                        window.Paytm.CheckoutJS.close();
                      } else {
                        const path = ioclCustomerPath
                          ? `/payment-failed/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
                          : `/payment-failed/${countryCode}/${deviceId}`;
                        navigate(path, {
                          state: {
                            amount,
                            deviceId,
                            ...(ioclCustomerPath && { iocl: true }),
                          },
                        });
                        window.Paytm.CheckoutJS.close();
                      }
                    },
                    notifyMerchant: function (eventName, data) {
                      console.log("notifyMerchant handler function called");
                      console.log("eventName => ", eventName);
                      console.log("data => ", data);
                    },
                  },
                };

                window.Paytm.CheckoutJS.onLoad(
                  function executeAfterCompleteLoad() {
                    window.Paytm.CheckoutJS.init(config)
                      .then(function onSuccess() {
                        window.Paytm.CheckoutJS.invoke();
                        setLoading(false);
                      })
                      .catch(function onError(error) {
                        console.error(
                          "Paytm Checkout initialization error:",
                          error
                        );
                        toast.error("Failed to initialize Paytm Checkout.");
                        setLoading(false);
                      });
                  }
                );
              }
            };

            script.onerror = () => {
              toast.error("Failed to load Paytm Checkout script.");
              setLoading(false);
            };

            document.body.appendChild(script);

            // Cleanup script after execution
            return () => {
              document.body.removeChild(script);
            };
          } else {
            toast.error("Payment initiation failed. Missing order ID.");
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error("Payment retry encountered an error.");
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "48px",
                height: "48px",
                borderRadius: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(41, 45, 51, 0.08)",
              }}
            >
              <span
                style={{ fontWeight: 300 }}
                className="material-symbols-outlined"
              >
                currency_rupee_circle
              </span>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                color="#2F2F2F"
                fontSize={"14px"}
                fontFamily={"mySecondFont"}
              >
                Amount to be charged
              </Typography>
              <Typography
                color="#2F2F2F"
                fontSize={"32px"}
                fontFamily={"myFirstFont"}
                textAlign={"center"}
                sx={{
                  background:
                    "linear-gradient(96deg, #FF7B52 11.82%, #E50000 92.52%)",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                ₹{amount}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Divider sx={{ width: "300px" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                }}
              >
                {" "}
                <Typography color="#272727" fontFamily={"myThirdFont"}>
                  Charging amount
                </Typography>
                <Typography fontFamily={"myThirdFont"}>₹{amount}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                }}
              >
                {" "}
                <Typography fontFamily={"myThirdFont"} color="#272727">
                  Outstanding amount
                </Typography>
                <Typography fontFamily={"myThirdFont"}>
                  ₹{outStandingAmount?.toFixed(2)}
                </Typography>
              </Box>
              <Divider sx={{ width: "300px" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "8px",
                }}
              >
                {" "}
                <Typography fontFamily={"mySecondFont"} color="#272727">
                  Total payable
                </Typography>
                <Typography
                  fontFamily={"mySecondFont"}
                  color="#00733D"
                  fontSize={"18px"}
                >
                  ₹{totalamount}
                </Typography>
              </Box>
              <Divider sx={{ width: "300px" }} />
            </Box>
            <Typography
              textAlign={"center"}
              fontSize={"18px"}
              fontFamily={"mySecondFont"}
              color="#262626"
            >
              Are you sure you want charge for the amount of ₹{totalamount}?
            </Typography>

            {/* Buttons */}
            <Box sx={{ display: "flex", gap: "8px", width: "100%", mt: "8px" }}>
              <Button
                onClick={handleClose}
                sx={{
                  background: "transparent",
                  borderRadius: "8px",
                  padding: "8px 24px",
                  width: "40%",
                  fontFamily: "mySecondFont",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#2F2F2F",
                  border: "1px solid rgba(41, 45, 51, 0.2)",
                  "&:hover": {
                    backgroundColor: "#F2F2F2",
                  },
                }}
              >
                No
              </Button>
              <Button
                onClick={handlePayment}
                sx={{
                  background: "#222",
                  borderRadius: "8px",
                  padding: "8px 24px",
                  width: "60%",
                  fontFamily: "mySecondFont",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#222",
                  },
                }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DueAmountModal;
