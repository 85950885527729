import { Box } from "@mui/material";
import React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import HelpCenterModal from "../Modals/HelpCenterModal";

const Header = () => {
  const navigate = useNavigate();

  const [openHelpCenter, setOpenHelpCenter] = React.useState(false);
  const handleOpen = () => setOpenHelpCenter(true);
  const handleClose = () => setOpenHelpCenter(false);

  const { deviceId, countryCode } = useParams();
  const location = useLocation();

  // const ioclCustomerPath = true;
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  return (
    <>
      <Box
        className="page"
        sx={{
          width: "100%",
          padding: "32px",
          background: "#fff",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Logo in center */}
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {ioclCustomerPath ? (
            <img
              style={{ width: "100%", maxWidth: "250px" }}
              src="/images/ioclhd.png"
              alt="IOCL Logo"
            />
          ) : (
            <img
              style={{ width: "30px" }}
              src="/images/mainLogo.png"
              alt="Main Logo"
            />
          )}
        </Box>

        {/* Content at right end */}
        {ioclCustomerPath && (
          <Box
            sx={{
              marginLeft: "auto",
              position: "absolute",
              right: "16px",
            }}
            onClick={handleOpen}
          >
            <span
              style={{ fontSize: "32px", color: "#222222", fontWeight: "300" }}
              className="material-symbols-outlined"
            >
              help
            </span>
          </Box>
        )}
      </Box>

      {/* modal */}
      <HelpCenterModal open={openHelpCenter} handleClose={handleClose} />
    </>
  );
};

export default Header;
