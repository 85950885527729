import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { endPoints } from "../../API/endpoints";

// Initial state (taken outside the slice)
const initialState = {
  deviceDetails: null,
  deviceDetailsStatus: "idle",
  sendOtpStatus: "idle",
  verifyOtpStatus: "idle",
  resendOtpStatus: "idle",
  verifyOtpResponse: null,
  makePaymentStatus: "idle", // Add this line
  makePaymentResponse: null, // Add this line
  error: null,
  makePaymentV2Status: "idle", // Initial status for makePaymentV2
  makePaymentV2Response: null, // Response data for makePaymentV2
  outstandingAmount: null,
  outstandingAmountStatus: "idle",
  onGoingSession: null, // Add ongoing session data
  onGoingSessionStatus: "idle",
  refreshApiStatus: "idle",
  refreshApiData: null,
  sseStatus: "idle",
  sseData: null,
  eventSource: null,
  stopSessionStatus: "idle",
  stopSessionResponse: null,
};

// Thunks for async API calls
export const fetchDeviceDetails = createAsyncThunk(
  "directPayment/fetchDeviceDetails",
  async (deviceID, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${endPoints.getDeviceDetails}/${deviceID}`
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const sendOTP = createAsyncThunk(
  "directPayment/sendOTP",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.sendOTP, {
        phone: phoneNumber,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const resendOTP = createAsyncThunk(
  "directPayment/resendOTP",
  async (phoneNumber, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.resendOTP, {
        phone: phoneNumber,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  "directPayment/verifyOTP",
  async ({ phoneNumber, otp, name }, { rejectWithValue }) => {
    try {
      const response = await axios.post(endPoints.verifyOTP, {
        phone: phoneNumber,
        otp: otp,
        name: name,
      });
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const makePayment = createAsyncThunk(
  "directPayment/makePayment",
  async ({ amount, deviceId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.paytmInit,
        { amount: amount, deviceId: deviceId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const makePaymentV2 = createAsyncThunk(
  "directPayment/makePaymentV2",
  async ({ amount, deviceId }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        endPoints.paytmInitv2,
        { amount, deviceId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data; // Adjust according to API response
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk for fetching the outstanding amount
export const fetchOutstandingAmount = createAsyncThunk(
  "directPayment/fetchOutstandingAmount",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.outstandingAmount}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data; // Assuming the response data is inside `.data`
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Add a new thunk for the ongoing session API
export const fetchOnGoingSession = createAsyncThunk(
  "directPayment/fetchOnGoingSession",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(endPoints.onGoinfSession, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.data; // Assuming the response data is inside `.data`
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Add new thunks
export const fetchRefreshDetails = createAsyncThunk(
  "directPayment/fetchRefreshDetails",
  async (sessionId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(`${endPoints.refreshApi}/${sessionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const initializeSSEConnection = createAsyncThunk(
  "directPayment/initializeSSE",
  async (sessionId, { dispatch, rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const eventSource = new EventSource(`${endPoints.SSEApi}/${sessionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      });

      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          dispatch(updateSSEData(data));
        } catch (error) {
          console.error("Error parsing SSE data:", error);
        }
      };

      eventSource.onerror = (error) => {
        console.error("SSE Error:", error);
        eventSource.close();
      };

      return eventSource;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const stopSession = createAsyncThunk(
  "directPayment/stopSession",
  async (sessionId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${endPoints.stopSessionApi}`,
        { sessionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice to handle the API state
const apiSlice = createSlice({
  name: "directPayment",
  initialState,
  // Add this new action to your reducers
  reducers: {
    updateSSEData: (state, action) => {
      state.sseData = action.payload;
    },
    closeSSEConnection: (state) => {
      if (state.eventSource) {
        state.eventSource.close();
      }
      state.eventSource = null;
      state.sseStatus = "idle";
      state.sseData = null;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchDeviceDetails
    builder
      .addCase(fetchDeviceDetails.pending, (state) => {
        state.deviceDetailsStatus = "loading";
      })
      .addCase(fetchDeviceDetails.fulfilled, (state, action) => {
        state.deviceDetails = action.payload;
        state.deviceDetailsStatus = "succeeded";
      })
      .addCase(fetchDeviceDetails.rejected, (state, action) => {
        state.deviceDetailsStatus = "failed";
        state.error = action.payload;
      });

    // Handle sendOTP
    builder
      .addCase(sendOTP.pending, (state) => {
        state.sendOtpStatus = "loading";
      })
      .addCase(sendOTP.fulfilled, (state) => {
        state.sendOtpStatus = "succeeded";
      })
      .addCase(sendOTP.rejected, (state, action) => {
        state.sendOtpStatus = "failed";
        state.error = action.payload;
      });

    // Handle resendOTP
    builder
      .addCase(resendOTP.pending, (state) => {
        state.resendOtpStatus = "loading";
      })
      .addCase(resendOTP.fulfilled, (state) => {
        state.resendOtpStatus = "succeeded";
      })
      .addCase(resendOTP.rejected, (state, action) => {
        state.resendOtpStatus = "failed";
        state.error = action.payload;
      });

    // Handle verifyOTP
    builder
      .addCase(verifyOTP.pending, (state) => {
        state.verifyOtpStatus = "loading";
      })
      .addCase(verifyOTP.fulfilled, (state, action) => {
        state.verifyOtpResponse = action.payload; // Store the response data
        state.verifyOtpStatus = "succeeded";
      })
      .addCase(verifyOTP.rejected, (state, action) => {
        state.verifyOtpStatus = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(makePayment.pending, (state) => {
        state.makePaymentStatus = "loading";
      })
      .addCase(makePayment.fulfilled, (state, action) => {
        state.makePaymentResponse = action.payload; // Store the response data
        state.makePaymentStatus = "succeeded";
      })
      .addCase(makePayment.rejected, (state, action) => {
        state.makePaymentStatus = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(makePaymentV2.pending, (state) => {
        state.makePaymentV2Status = "loading"; // Set status to loading when request is sent
      })
      .addCase(makePaymentV2.fulfilled, (state, action) => {
        state.makePaymentV2Response = action.payload; // Store the successful response
        state.makePaymentV2Status = "succeeded"; // Set status to succeeded
      })
      .addCase(makePaymentV2.rejected, (state, action) => {
        state.makePaymentV2Status = "failed"; // Set status to failed
        state.error = action.payload || "Something went wrong"; // Store the error message
      });
    // Handle fetchOutstandingAmount
    builder
      .addCase(fetchOutstandingAmount.pending, (state) => {
        state.outstandingAmountStatus = "loading";
      })
      .addCase(fetchOutstandingAmount.fulfilled, (state, action) => {
        state.outstandingAmount = action.payload;
        state.outstandingAmountStatus = "succeeded";
      })
      .addCase(fetchOutstandingAmount.rejected, (state, action) => {
        state.outstandingAmountStatus = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(fetchOnGoingSession.pending, (state) => {
        state.onGoingSessionStatus = "loading";
      })
      .addCase(fetchOnGoingSession.fulfilled, (state, action) => {
        state.onGoingSession = action.payload; // Store the response data
        state.onGoingSessionStatus = "succeeded";
      })
      .addCase(fetchOnGoingSession.rejected, (state, action) => {
        state.onGoingSessionStatus = "failed";
        state.error = action.payload; // Store the error message
      });
    // Add these to your extraReducers
    builder
      .addCase(fetchRefreshDetails.pending, (state) => {
        state.refreshApiStatus = "loading";
      })
      .addCase(fetchRefreshDetails.fulfilled, (state, action) => {
        state.refreshApiStatus = "succeeded";
        state.refreshApiData = action.payload;
      })
      .addCase(fetchRefreshDetails.rejected, (state, action) => {
        state.refreshApiStatus = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(initializeSSEConnection.pending, (state) => {
        state.sseStatus = "loading";
      })
      .addCase(initializeSSEConnection.fulfilled, (state, action) => {
        state.sseStatus = "succeeded";
        state.eventSource = action.payload;
      })
      .addCase(initializeSSEConnection.rejected, (state, action) => {
        state.sseStatus = "failed";
        state.error = action.payload;
      });
    builder
      .addCase(stopSession.pending, (state) => {
        state.stopSessionStatus = "loading";
      })
      .addCase(stopSession.fulfilled, (state, action) => {
        state.stopSessionResponse = action.payload;
        state.stopSessionStatus = "succeeded";
      })
      .addCase(stopSession.rejected, (state, action) => {
        state.stopSessionStatus = "failed";
        state.error = action.payload;
      });
  },
});

export const { resetState, updateSSEData, closeSSEConnection } =
  apiSlice.actions;
export default apiSlice.reducer;
