import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";

const IOCLFooter = () => {
  const location = useLocation();
  const ioclFooter = location.pathname.includes("/home/");

  return (
    <Box
      className="page"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        gap: "4px",
        width: "100%",
        // padding: "16px",
        mt: "4px",
        background: "#fff",
        paddingBottom: ioclFooter ? "16px" : "70px",
      }}
    >
      <Typography
        color="#2f2f2f"
        fontSize={"12px"}
        sx={{ textAlign: "right", fontStyle: "italic" }}
      >
        Powered By Massive Mobility
      </Typography>
      {/* Logo */}
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        <Box
          sx={{
            // background: "linear-gradient(180deg, #E9E9E9 0%, #F8F8F8 100%)",
            width: "48px",
            height: "48px",
            borderRadius: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <img
              style={{ width: "32px" }}
              src="/images/massiveLogo.png"
              alt=""
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default IOCLFooter;
