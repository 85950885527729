import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDeviceDetails,
  makePayment,
} from "../../redux/action/directPaymentSlice";
import { useNavigate, useLocation } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import StatusModal from "./StatusModal";
import { toast } from "sonner";
import { currencySymbols } from "../../utils/currencySymbols";
import { countryToCurrency } from "../../utils/countryCodes";
import { endPoints } from "../../API/endpoints";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "#fff",
  boxShadow: 24,
  padding: "24px 16px",
  borderRadius: "8px",
};

const ConfirmAmountModal = ({
  open,
  handleClose,
  amount,
  deviceId,
  countryCode,
  phoneNumber,
  setRetryLoading,
}) => {
  const [openStatusModal, setOpenStatusModal] = React.useState(false);
  const handleOpenStatusModal = () => setOpenStatusModal(true);
  const handleCloseStatusModal = () => setOpenStatusModal(false);

  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const makePaymentResponse = useSelector(
    (state) => state.directPayment.makePaymentResponse
  );

  // const ioclCustomerPath = true;
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  // Extract country code from pathname
  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2); // Assuming country codes are 2 letters

  const currency = countryToCurrency[countryCodeName] || "INR"; // Default to INR if not found
  const currencySymbol = currencySymbols[currency] || "₹"; // Default to ₹

  const stripeStatus = location.pathname.includes("/NZ/"); // This will control whether Stripe or Paytm is used

  const handlePayment = async () => {
    setLoading(true);
    try {
      // Fetch the device details
      const deviceDetails = await dispatch(
        fetchDeviceDetails(deviceId)
      ).unwrap();

      // Check if the device status and connectorType are valid for payment
      const statusCheck =
        deviceDetails?.isOcpi === true && deviceDetails?.status === "Available"
          ? true
          : (deviceDetails?.status === "Available" &&
            deviceDetails?.connectorType === "AC Socket") ||
          deviceDetails?.status === "Preparing";

      if (statusCheck) {
        if (stripeStatus) {
          // Handle Stripe Payment
          handleStripePayment();
        } else {
          // Handle Paytm Payment
          dispatch(makePayment({ amount, deviceId }));
          handleClose(); // Optionally close the modal
        }
      } else {
        // If status is invalid, open the StatusModal
        handleClose();
        handleOpenStatusModal();
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error fetching device details:", error);
      setLoading(false);
    }
  };

  // Stripe Payment Handler
  const handleStripePayment = async () => {
    try {
      const token = localStorage.getItem("token");
      const paymentIntentResponse = await fetch(endPoints.paytmGateway, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount, deviceId, phoneNumber }), // Sending amount to backend for creating payment intent
      }).then((res) => res.json());

      if (paymentIntentResponse?.url) {
        window.location.href = paymentIntentResponse?.url; // Redirect to Stripe Checkout
      } else {
        toast.error("Failed to create checkout session.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Stripe Payment Error:", error);
      toast.error("Error with Stripe payment");
    }
  };

  // Paytm Checkout Integration
  React.useEffect(() => {
    if (!stripeStatus && makePaymentResponse && makePaymentResponse.orderId) {
      // Load Paytm Checkout script
      const script = document.createElement("script");
      script.src = `${endPoints.stripeGateway}/${makePaymentResponse.mid}.js`;
      script.type = "application/javascript";
      script.crossOrigin = "anonymous";
      script.onload = () => onScriptLoad();
      document.body.appendChild(script);

      // Clean up script after it has loaded
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [makePaymentResponse, stripeStatus]);

  const onScriptLoad = () => {
    if (!stripeStatus && window.Paytm && window.Paytm.CheckoutJS) {
      const config = {
        root: "",
        flow: "DEFAULT",
        merchant: { redirect: false },
        data: {
          orderId: makePaymentResponse.orderId,
          token: makePaymentResponse.txnToken,
          tokenType: "TXN_TOKEN",
          amount: makePaymentResponse.txnAmount.value,
        },
        handler: {
          transactionStatus: function (data) {
            console.log('data?.STATUS', data?.STATUS);
            if (data?.STATUS === "TXN_SUCCESS") {
              if (ioclCustomerPath) {
                navigate(
                  `/charging-start/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
                );
                window.Paytm.CheckoutJS.close();
              } else {
                navigate(`/charging-start/${countryCode}/${deviceId}`);
                window.Paytm.CheckoutJS.close();
              }
            } else {
              if (ioclCustomerPath) {
                navigate(
                  `/payment-failed/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`,
                  {
                    state: { amount, deviceId, iocl: true },
                  }
                );
                window.Paytm.CheckoutJS.close();
              } else {
                navigate(`/payment-failed/${countryCode}/${deviceId}`, {
                  state: { amount, deviceId },
                });
                window.Paytm.CheckoutJS.close();
              }
            }
            console.log(data);
          },
          notifyMerchant: function (eventName, data) {
            console.log("notifyMerchant handler function called");
            console.log("eventName => ", eventName);
            console.log("data => ", data);
          },
        },
      };

      window.Paytm.CheckoutJS.onLoad(function executeAfterCompleteLoad() {
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            window.Paytm.CheckoutJS.invoke();
            setLoading(false);
            setRetryLoading(false);
          })
          .catch(function onError(error) {
            console.log("error => ", error);
            setLoading(false);
            setRetryLoading(false);
          });
      });
    }
  };

  return (
    <div>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
          }}
        >
          <CircularProgress
            sx={{
              color: "#FFF",
            }}
            thickness={5}
            size={60}
          />
        </Box>
      ) : (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "48px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "rgba(41, 45, 51, 0.08)",
                }}
              >
                <span
                  style={{ fontWeight: 300 }}
                  className="material-symbols-outlined"
                >
                  currency_rupee_circle
                </span>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
              >
                <Typography
                  color="#2F2F2F"
                  fontSize={"14px"}
                  fontFamily={"mySecondFont"}
                >
                  Charging amount
                </Typography>
                <Typography
                  color="#2F2F2F"
                  fontSize={"32px"}
                  fontFamily={"myFirstFont"}
                  textAlign={"center"}
                  sx={{
                    background:
                      "linear-gradient(96deg, #FF7B52 11.82%, #E50000 92.52%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {`${currencySymbol}${amount}`}
                </Typography>
              </Box>
              <Typography
                fontSize={"18px"}
                fontFamily={"mySecondFont"}
                color="#262626"
                textAlign={"center"}
              >
                Are you sure you want charge for the amount of{" "}
                {`${currencySymbol}${amount}`}?
              </Typography>

              {/* Buttons */}
              <Box
                sx={{ display: "flex", gap: "8px", width: "100%", mt: "8px" }}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    background: "transparent",
                    borderRadius: "8px",
                    padding: "8px 24px",
                    width: "40%",
                    fontFamily: "mySecondFont",
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#2F2F2F",
                    border: "1px solid rgba(41, 45, 51, 0.2)",
                    "&:hover": {
                      backgroundColor: "#F2F2F2",
                    },
                  }}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={handlePayment}
                  sx={{
                    background:
                      "linear-gradient(96deg, #FF7B52 11.82%, #E50000 92.52%)",
                    borderRadius: "8px",
                    padding: "8px 24px",
                    width: "60%",
                    fontFamily: "mySecondFont",
                    fontSize: "12px",
                    fontWeight: 700,
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#F2F2F2",
                    },
                  }}
                >
                  CONFIRM
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
      <StatusModal
        open={openStatusModal}
        handleClose={handleCloseStatusModal}
        amount={amount}
        deviceId={deviceId}
      />
    </div>
  );
};

export default ConfirmAmountModal;
