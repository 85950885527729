import { Box } from "@mui/material";
import React from "react";
import HomePage from "../../components/Home/HomePage";
import { useLocation } from "react-router-dom";
import IOCLHomePage from "../../components/Home/IOCLHomePage";

const Home = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  return <Box>{ioclCustomerPath ? <IOCLHomePage /> : <HomePage />}</Box>;
};

export default Home;
