const BASE_URL = "https://backend.1charging.com";
// const BASE_URL = "http://:3000";
// const BASE_URL = "http://localhost:3000";

export const endPoints = {
  // All - Api
  getDeviceDetails: BASE_URL + "/api/v4/charging-point-status",
  sendOTP: BASE_URL + "/api/v4/users/send-otp",
  resendOTP: BASE_URL + "/api/v4/users/resend-otp",
  verifyOTP: BASE_URL + "/api/v4/users/verify-otp",
  paytmInit: BASE_URL + "/api/direct-payment",
  outstandingAmount: BASE_URL + "/api/v4/users/outstanding-session",

  paytmInitv2: BASE_URL + "/api/direct-payment/v2",
  onGoinfSession: BASE_URL + "/api/v4/users/ongoing-ocpi-direct-session",
  refreshApi: BASE_URL + "/api/ocpi-sessions/live-updates/details",
  SSEApi: BASE_URL + "/api/ocpi-sessions/live-updates",
  stopSessionApi: BASE_URL + "/api/ocpi/app-charging/stop",

  // payment gateways

  //For Test
  // paytmGateway:
  //   "http://backendtest.1charging.com/api/direct-payment/create-intent",

  //For Prod

  paytmGateway:
    "https://backend.1charging.com/api/direct-payment/create-intent",

  stripeGateway:
    "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants",

  paytmGatewayV2:
    "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants",
};
