import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOutstandingAmount,
  resendOTP,
  verifyOTP,
} from "../../redux/action/directPaymentSlice";

const OTPVerify = () => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(15); // Countdown starts from 15
  const [isResendAvailable, setIsResendAvailable] = useState(false); // Track if resend is available
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const phoneNumber = location.state?.phoneNumber || "";
  const customerName = location.state?.name || "";
  const { deviceId, countryCode } = useParams();
  const otpInputRef = useRef(null);

  useEffect(() => {
    dispatch(fetchOutstandingAmount());
  }, [dispatch]);

  const outStandingAmount = useSelector(
    (state) => state.directPayment.outstandingAmount
  );

  console.log(
    "hjhjhjhjhjhj",
    outStandingAmount?.outstandingSessions[0]?.status
  );

  // const ioclCustomerPath = true;
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  console.log("hhhhss", customerName, phoneNumber);

  const verifyOtpStatus = useSelector(
    (state) => state.directPayment.verifyOtpStatus
  );
  const otpError = useSelector((state) => state.directPayment.error);

  const lastFourDigits = phoneNumber.slice(-4);

  const handleBack = () => {
    if (ioclCustomerPath) {
      if (countryCode && deviceId) {
        navigate(
          `/verification/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
        );
      } else {
        navigate("/"); // Fallback if params are missing
      }
    } else {
      if (countryCode && deviceId) {
        navigate(`/verification/${countryCode}/${deviceId}`);
      } else {
        navigate("/"); // Fallback if params are missing
      }
    }
  };

  const handleSubmitOtp = () => {
    if (otp.trim() === "") {
      toast.error("Please Enter the OTP.");
      return;
    }

    // Convert OTP to number
    const otpNumber = parseInt(otp, 10);

    // Check if OTP is a valid number
    if (isNaN(otpNumber)) {
      toast.error("Invalid OTP format.");
      return;
    }

    // Dispatch the verifyOTP action with phoneNumber and OTP
    dispatch(verifyOTP({ phoneNumber, otp: otpNumber, name: customerName }))
      .unwrap()
      .then(() => {
        if (ioclCustomerPath) {
          if (outStandingAmount?.outstandingSessions[0]?.status === "ACTIVE") {
            navigate(
              `/iocl-charging-start/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
            );
            return;
          }
          navigate(
            `/home/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
          );
        } else {
          navigate(`/home/${countryCode}/${deviceId}`); // Navigate to home on success
        }
      })
      .catch((err) => {
        console.error("Failed to verify OTP:", err);
        toast.error("Failed to verify OTP. Please try again.");
      });
  };

  const handleResendOtp = () => {
    // Dispatch the resendOTP action
    dispatch(resendOTP(phoneNumber))
      .unwrap()
      .then(() => {
        // Reset timer and disable resend
        setTimer(15);
        setIsResendAvailable(false);
        toast.success("OTP Sent Successfully");
      })
      .catch((err) => {
        console.error("Failed to resend OTP:", err);
        toast.error("Failed to resend OTP. Please try again.");
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent default form submission if any
      handleSubmitOtp();
    }
  };

  const handleOtpChange = (value) => {
    // Set the OTP value
    setOtp(value);

    // Maintain cursor position for deleting
    if (otpInputRef.current) {
      const input = otpInputRef.current;
      input.focus();
      input.setSelectionRange(value.length, value.length);
    }
  };

  // Countdown logic
  useEffect(() => {
    let countdown;

    if (timer > 0) {
      countdown = setTimeout(() => setTimer(timer - 1), 1000);
    } else {
      setIsResendAvailable(true); // Enable resend when timer reaches 0
    }

    return () => clearTimeout(countdown); // Clear the timeout when the component unmounts
  }, [timer]);

  // Web OTP API for auto-filling OTP
  // useEffect(() => {
  //   if ("OTPCredential" in window) {
  //     navigator.credentials
  //       .get({ otp: { transport: ["sms"] } })
  //       .then((otpCredential) => {
  //         if (otpCredential && otpCredential.code) {
  //           setOtp(otpCredential.code); // Auto-fill OTP
  //           handleSubmitOtp(); // Optionally, auto-submit the OTP
  //         }
  //       })
  //       .catch((err) => {
  //         console.error("OTP auto-fill failed:", err);
  //       });
  //   }
  // }, []);

  return (
    <Box
      className="page"
      sx={{ mt: "4px", background: "#fff", padding: "16px" }}
    >
      <Box
        sx={{
          mt: "22px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "center",
        }}
      >
        <Typography
          color="#292D33"
          fontFamily={"'Montserrat', sans-serif"}
          letterSpacing={"1.12px"}
          fontWeight={600}
          fontSize={"14px"}
        >
          SCAN. PAY. CHARGE.
        </Typography>
        <Box sx={{ mt: "8px" }}>
          <img
            style={{ maxWidth: "150px", width: "100%" }}
            src="/images/upi.png"
            alt=""
          />
        </Box>
      </Box>

      {/* Otp box */}
      <Typography
        color="#121212"
        fontFamily={"mySecondFont"}
        fontSize={"18px"}
        textAlign={"center"}
        mt={"48px"}
      >
        Enter OTP sent to xxxxxx{lastFourDigits}
      </Typography>

      {/* OTP Inputs */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
          mt: "8px",
        }}
      >
        <OtpInput
          ref={otpInputRef}
          value={otp}
          onChange={handleOtpChange}
          numInputs={4}
          inputType="number"
          renderInput={(props) => (
            <input {...props} onKeyDown={handleKeyDown} />
          )}
          inputStyle={{
            padding: "0px 16px",
            borderRadius: "8px",
            marginLeft: "8px",
            height: "48px",
            border: "1px solid #2F2F2F",
            fontSize: "18px",
            fontFamily: "mySecondFont",
          }}
        />
      </Box>
      <Typography
        fontFamily={"myThirdFont"}
        color={"#333"}
        textAlign={"center"}
        mt={"16px"}
      >
        Didn’t receive OTP?{" "}
        {isResendAvailable ? (
          <span
            style={{
              fontFamily: "mySecondFont",
              cursor: "pointer",
              color: "#007bff",
            }}
            onClick={handleResendOtp}
          >
            Resend OTP
          </span>
        ) : (
          <span style={{ fontFamily: "mySecondFont" }}>
            Resend OTP in 00:{timer < 10 ? `0${timer}` : timer}s
          </span>
        )}
      </Typography>

      {/* Buttons */}
      <Box
        sx={{
          mt: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Button
          onClick={handleSubmitOtp}
          sx={{
            background: "#222",
            borderRadius: "8px",
            padding: "12px 24px",
            width: "100%",
            fontFamily: "mySecondFont",
            color: "#fcfcfc",
            letterSpacing: "1.28px",
            fontSize: "16px",
            ":hover": {
              background: "#222",
            },
          }}
          // onClick={handleVerify}
        >
          VERIFY
        </Button>
        <Button
          sx={{
            background: "transparent",
            borderRadius: "8px",
            padding: "8px 24px",
            width: "100%",
            fontFamily: "mySecondFont",
            color: "#222",
            letterSpacing: "1.28px",
            fontSize: "16px",
            border: "1px solid #c1c1c1",
            mb: "166px",
          }}
          onClick={handleBack}
        >
          BACK
        </Button>
      </Box>
    </Box>
  );
};

export default OTPVerify;
