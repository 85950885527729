import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import { routes } from "./routes/routes";
import { Toaster } from "sonner";
import IOCLFooter from "./components/Footer/IOCLFooter";

function App() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";
  return (
    <>
      <Header />
      <Routes>
        {routes.map((item) => (
          <Route path={item.path} element={item.element} />
        ))}
      </Routes>
      <Toaster richColors position="top-right" />
      {ioclCustomerPath ? <IOCLFooter /> : <Footer />}
    </>
  );
}

export default App;
