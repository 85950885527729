import { Box } from "@mui/material";
import React from "react";
import IOCLChargingStatus from "../../components/chargingStart/IOCLChargingStatus";

const IOCLStartChargingPage = () => {
  return (
    <Box>
      <IOCLChargingStatus />
    </Box>
  );
};

export default IOCLStartChargingPage;
