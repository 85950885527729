import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";
import {
  fetchOnGoingSession,
  fetchRefreshDetails,
  fetchSSEUpdates,
  initializeSSEConnection,
  makePaymentV2,
  stopSession,
} from "../../redux/action/directPaymentSlice";
import { endPoints } from "../../API/endpoints";
import StopChargingModal from "../Modals/StopChargingModal";

const DetailBox = ({ label, value, children }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
    <Typography fontSize="14px" fontFamily="myThirdFont" color="#2F2F2F">
      {label}
    </Typography>
    <Typography
      fontSize="18px"
      fontFamily="mySecondFont"
      color="#1C1C1C"
      sx={{ display: "flex", alignItems: "center" }}
    >
      {children || value}
    </Typography>
  </Box>
);

const IOCLChargingStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
  const [isExpired, setIsExpired] = useState(false);
  const [isCharging, setIsCharging] = useState(true);
  const { deviceId, countryCode } = useParams();
  const [loading, setLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { onGoingSession, sseData, refreshApiData } = useSelector(
    (state) => state.directPayment
  );

  const sessionData = sseData || refreshApiData;

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (onGoingSession === null) {
  //       navigate(
  //         `/home/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
  //       );
  //     }
  //   }, 3000); // 1000 milliseconds = 1 second

  //   // Cleanup function to clear the timeout if component unmounts
  //   return () => clearTimeout(timer);
  // }, [navigate, onGoingSession, countryCode, deviceId]);

  useEffect(() => {
    // Function to calculate remaining time
    const calculateRemainingTime = () => {
      if (
        !sseData?.ocpiSession?.start_date_time &&
        !refreshApiData?.ocpiSession?.start_date_time
      ) {
        return 0;
      }

      const startTime = new Date(
        sseData?.ocpiSession?.start_date_time ??
          refreshApiData?.ocpiSession?.start_date_time
      ).getTime();

      console.log("kkkkkkk", startTime);
      const currentTime = new Date().getTime();
      const elapsedSeconds = Math.floor((currentTime - startTime) / 1000);
      const timeLimit = 5 * 60; // 5 minutes in seconds
      const remaining = timeLimit - elapsedSeconds;

      return Math.max(0, remaining); // Don't go below 0
    };

    // Initialize timer with calculated time
    const initialTime = calculateRemainingTime();
    setTimeLeft(initialTime);
    setIsExpired(initialTime === 0);

    // Only start interval if there's time remaining
    if (initialTime > 0) {
      const timer = setInterval(() => {
        const remaining = calculateRemainingTime();
        setTimeLeft(remaining);

        if (remaining <= 0) {
          setIsExpired(true);
          clearInterval(timer);
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [
    sseData?.ocpiSession?.start_date_time,
    refreshApiData?.ocpiSession?.start_date_time,
  ]);

  const makePaymentV2Response = useSelector(
    (state) => state.directPayment.makePaymentV2Response
  );

  // useEffect(() => {
  //   if (onGoingSession?.session?.paymentStatus === "UNPAID") {
  //     dispatch(
  //       makePaymentV2({
  //         amount: makePaymentV2Response?.txnAmount?.value,
  //         deviceId,
  //       })
  //     );
  //   }
  // }, [dispatch, onGoingSession]);

  const queryParams = new URLSearchParams(location.search);
  // Check if 'iocl' query parameter is true
  const ioclCustomerPath = queryParams.get("iocl") === "true";

  // Convert seconds to minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  // Format numbers to always show two digits
  const formatNumber = (num) => String(num).padStart(2, "0");

  const minDigits = formatNumber(minutes).split("");
  const secDigits = formatNumber(seconds).split("");

  const handlePayment = () => {
    const newAmount = makePaymentV2Response?.txnAmount?.value; // You can modify this as needed

    setLoading(true); // Show the loader during the process

    dispatch(makePaymentV2({ amount: newAmount, deviceId }))
      .then((res) => {
        if (res.error) {
          toast.error("Payment retry failed. Please try again.");
          setLoading(false);
        } else {
          toast.success("Redirecting to Paytm...");
          const response = res.payload;

          if (response?.orderId) {
            // Load Paytm Checkout script
            const script = document.createElement("script");
            script.src = `${endPoints.paytmGatewayV2}/${response.mid}.js`;
            script.type = "application/javascript";
            script.crossOrigin = "anonymous";
            script.onload = () => onScriptLoad(response); // Pass the response for Paytm config
            document.body.appendChild(script);

            // Clean up script after it has loaded
            script.onerror = () => {
              toast.error("Failed to load Paytm Checkout script.");
              setLoading(false);
            };

            return () => {
              document.body.removeChild(script);
            };
          } else {
            toast.error("Payment initiation failed. Missing order ID.");
            setLoading(false);
          }
        }
      })
      .catch(() => {
        toast.error("Payment retry encountered an error.");
        setLoading(false);
      });
  };

  const onScriptLoad = (makePaymentV2Response) => {
    if (window.Paytm && window.Paytm.CheckoutJS) {
      const config = {
        root: "",
        flow: "DEFAULT",
        merchant: { redirect: false },
        data: {
          orderId: makePaymentV2Response.orderId,
          token: makePaymentV2Response.txnToken,
          tokenType: "TXN_TOKEN",
          amount: makePaymentV2Response.txnAmount.value,
        },
        handler: {
          transactionStatus: function (data) {
            console.log("data?.STATUS", data?.STATUS);
            setLoading(false);

            if (data?.STATUS === "TXN_SUCCESS") {
              const path = ioclCustomerPath
                ? `/iocl-charging-start/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
                : `/iocl-charging-start/${countryCode}/${deviceId}`;
              navigate(path);
              window.Paytm.CheckoutJS.close();
            } else {
              const path = ioclCustomerPath
                ? `/payment-failed/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
                : `/payment-failed/${countryCode}/${deviceId}`;
              navigate(path, {
                state: {
                  amount: makePaymentV2Response?.txnAmount?.value,
                  deviceId,
                  ...(ioclCustomerPath && { iocl: true }),
                },
              });
              window.Paytm.CheckoutJS.close();
            }
            console.log(data);
          },
          notifyMerchant: function (eventName, data) {
            console.log("notifyMerchant handler function called");
            console.log("eventName => ", eventName);
            console.log("data => ", data);
          },
        },
      };

      window.Paytm.CheckoutJS.onLoad(function executeAfterCompleteLoad() {
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            window.Paytm.CheckoutJS.invoke();
            setLoading(false);
            setRetryLoading(false);
          })
          .catch(function onError(error) {
            console.log("error => ", error);
            toast.error("Paytm Checkout initialization failed.");
            setLoading(false);
            setRetryLoading(false);
          });
      });
    }
  };

  useEffect(() => {
    dispatch(fetchOnGoingSession());
  }, [dispatch]);

  useEffect(() => {
    if (!onGoingSession?.session?.uid) return;

    // Dispatch SSE connection only once
    dispatch(initializeSSEConnection(onGoingSession.session.uid));

    // Fetch refresh details every 1 second
    const intervalId = setInterval(() => {
      dispatch(fetchRefreshDetails(onGoingSession.session.uid));
    }, 1000);

    // Stop calling after 10 seconds
    setTimeout(() => {
      clearInterval(intervalId);
    }, 10000);

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [dispatch, onGoingSession?.session?.uid]);

  const handleContinue = () => {
    navigate(`/home/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`);
  };

  const handleRefreshClick = () => {
    if (onGoingSession?.session?.uid) {
      dispatch(fetchRefreshDetails(onGoingSession.session.uid))
        .unwrap()
        .then((data) => {
          console.log("API call successful:", data);
          // Handle successful response if needed
        })
        .catch((error) => {
          console.error("API call failed:", error);
          // Handle error response if needed
        });
    } else {
      alert("Session UID is required to refresh details.");
    }
  };

  const startDateTime =
    sseData?.ocpiSession?.start_date_time ||
    refreshApiData?.ocpiSession?.start_date_time;
  const endDateTime =
    sseData?.ocpiSession?.end_date_time ||
    refreshApiData?.ocpiSession?.end_date_time;
  const status =
    sseData?.ocpiSession?.status || refreshApiData?.ocpiSession?.status;

  useEffect(() => {
    if (!startDateTime || status === "COMPLETED") return;

    const calculateElapsedTime = () => {
      const startTime = new Date(startDateTime).getTime();
      const currentTime = new Date().getTime();
      const elapsed = Math.floor((currentTime - startTime) / 1000); // elapsed time in seconds
      setElapsedTime(elapsed);
    };

    calculateElapsedTime();
    const timer = setInterval(calculateElapsedTime, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [startDateTime, status]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const calculateCompletedTime = () => {
    if (status === "COMPLETED" && startDateTime && endDateTime) {
      const startTime = new Date(startDateTime).getTime();
      const endTime = new Date(endDateTime).getTime();
      const elapsed = Math.floor((endTime - startTime) / 1000); // completed session time in seconds
      return formatTime(elapsed);
    }
    return formatTime(elapsedTime); // Default to elapsedTime if status isn't COMPLETED
  };

  console.log(sseData, "klkllklkl");

  const startTime = refreshApiData?.ocpiSession?.start_date_time
    ? new Date(refreshApiData.ocpiSession.start_date_time).toLocaleTimeString(
        "en-US",
        {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      )
    : "";

  return (
    <>
      {/* Timer */}
      {refreshApiData?.ocpiPendingSession?.paymentStatus === "UNPAID" &&
      refreshApiData?.ocpiPendingSession?.status === "ACTIVE" &&
      timeLeft > 0 ? (
        <Box
          sx={{
            padding: "16px",
            background: "#FFF",
            mt: "4px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span
              style={{
                color: "#790000",
                width: "32px",
                height: "32px",
                fontWeight: "300",
              }}
              className="material-symbols-outlined"
            >
              pace
            </span>
            <Typography
              fontFamily={"myThirdFont"}
              fontSize={"16px"}
              color="#790000"
            >
              Pay before the time runs out or the current charging session will
              be stopped
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: "4px",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <Box
                  sx={{
                    background: "#DDD",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <Typography
                    fontSize={"24px"}
                    color="#0c0c0c"
                    fontFamily={"mySecondFont"}
                  >
                    {minDigits[0]}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: "#DDD",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <Typography
                    fontSize={"24px"}
                    color="#0c0c0c"
                    fontFamily={"mySecondFont"}
                  >
                    {minDigits[1]}
                  </Typography>
                </Box>
              </Box>
              <Typography fontFamily={"myThirdFont"} color={"#777"}>
                Min
              </Typography>
            </Box>
            <Box>:</Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <Box
                  sx={{
                    background: "#DDD",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <Typography
                    fontSize={"24px"}
                    color="#0c0c0c"
                    fontFamily={"mySecondFont"}
                  >
                    {secDigits[0]}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    background: "#DDD",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <Typography
                    fontSize={"24px"}
                    color="#0c0c0c"
                    fontFamily={"mySecondFont"}
                  >
                    {secDigits[1]}
                  </Typography>
                </Box>
              </Box>
              <Typography fontFamily={"myThirdFont"} color={"#777"}>
                Sec
              </Typography>
            </Box>
          </Box>
          <Button
            sx={{
              background:
                "linear-gradient(94deg, #FF7B52 -20.04%, #E50000 122.31%)",
              borderRadius: "8px",
              padding: "12px 24px",
              width: "100%",
              fontFamily: "mySecondFont",
              color: "#fcfcfc",
              letterSpacing: "1.28px",
            }}
            onClick={handlePayment}
          >
            Proceed to pay ₹{makePaymentV2Response?.txnAmount?.value}
          </Button>
        </Box>
      ) : (
        ""
      )}
      {/* Charging Status */}
      <Box
        sx={{
          padding: "16px",
          background: "#FFF",
          mt: "4px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          textAlign={"center"}
          color="#2f2f2f"
          fontSize={"16px"}
          fontFamily={"mySecondFont"}
        >
          Status
        </Typography>
        <Typography
          fontSize={"24px"}
          fontFamily={"mySecondFont"}
          color={
            sessionData?.ocpiPendingSession?.status === "COMPLETED" &&
            sessionData?.ocpiPendingSession?.stopReason === "USER_REQUEST"
              ? "#790000"
              : sessionData?.ocpiSession?.status === "COMPLETED" ||
                sessionData?.ocpiSession?.status === "ACTIVE"
              ? "#008F37"
              : !sessionData ||
                sessionData === "" ||
                sessionData?.ocpiPendingSession?.status === "PENDING"
              ? "#156BBA"
              : "#790000"
          }
          textAlign={"center"}
        >
          {sessionData?.ocpiPendingSession?.status === "COMPLETED" &&
          sessionData?.ocpiPendingSession?.stopReason === "USER_REQUEST"
            ? "Charging Stopped"
            : sessionData?.ocpiSession?.status === "COMPLETED"
            ? "Charging Finished"
            : sessionData?.ocpiSession?.status === "ACTIVE"
            ? "Charging"
            : sessionData?.ocpiSession?.status === "REJECT"
            ? "Charging Stopped"
            : !sessionData ||
              sessionData === "" ||
              sessionData?.ocpiPendingSession?.status === "PENDING"
            ? "Initiating"
            : null}
        </Typography>
        <img
          style={{ width: "120px" }}
          src={
            sessionData?.ocpiPendingSession?.status === "COMPLETED" &&
            sessionData?.ocpiPendingSession?.stopReason === "USER_REQUEST"
              ? "/images/stopped.png"
              : sessionData?.ocpiSession?.status === "COMPLETED"
              ? "/images/charged.png"
              : !sessionData ||
                sessionData === "" ||
                sessionData?.ocpiPendingSession?.status === "PENDING"
              ? "/images/initimage.png"
              : sessionData?.ocpiSession?.status === "ACTIVE"
              ? "/images/charginglogo.png"
              : "/images/stopped.png"
          }
        />

        {/* Time - expire */}
        {sseData?.ocpiPendingSession?.stopReason === "5_MINUTES_EXPIRED" && (
          <Box
            sx={{
              mt: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography fontFamily={"myThirdFont"} fontSize={"16px"}>
              You have reached your 5min timing. If you want to continue your
              charging...
            </Typography>
            <Button
              onClick={handleContinue}
              sx={{
                borderRadius: "4px",
                background: "#222",
                color: "#fff",
                mt: "12px",
                fontFamily: "myThirdFont",

                ":hover": {
                  background: "#222",
                },
              }}
            >
              Click here
            </Button>
          </Box>
        )}

        {onGoingSession?.ocpiPendingSession?.status === "REJECTED" && (
          <Box
            sx={{
              mt: "16px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography fontFamily={"myThirdFont"} fontSize={"16px"}>
              Something Went Wrong, Please restart the process again.
            </Typography>
            <Button
              onClick={handleContinue}
              sx={{
                borderRadius: "4px",
                background: "#222",
                color: "#fff",
                mt: "12px",
                fontFamily: "myThirdFont",

                ":hover": {
                  background: "#222",
                },
              }}
            >
              Click here
            </Button>
          </Box>
        )}
      </Box>
      {/* Charging info */}
      <Box sx={{ padding: "16px", background: "#FFF", mt: "4px" }}>
        {(sseData?.ocpiSession?.status === "ACTIVE" ||
          sessionData?.ocpiPendingSession?.status === "ACTIVE") && (
          <Button
            sx={{
              border: "1px solid rgba(0, 0, 0, 0.20)",
              borderRadius: "8px",
              width: "100%",
              color: "#222",
              fontFamily: "mySecondFont",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
            onClick={handleRefreshClick}
          >
            Refresh
            <span
              className="material-symbols-outlined"
              style={{
                width: "24px",
                height: "24px",
                fontWeight: "300",
              }}
            >
              autorenew
            </span>
          </Button>
        )}

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            justifyContent: "center",
            width: "100%",
            mt: "16px",
          }}
        >
          {/* Energy Card */}
          <Box
            sx={{
              flex: "1 1 0",
              maxWidth: "110px",
              aspectRatio: "1/1.1",
              backgroundImage: "url('/images/energyConsumedBox.png')",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography
              sx={{
                color: "#FF7B52",
                fontSize: "20px",
                fontFamily: "mySecondFont",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {sseData?.ocpiSession?.kwh || refreshApiData?.ocpiSession?.kwh}
              <span
                style={{
                  color: "#E50000",
                  fontFamily: "mySecondFont",
                  textTransform: "none",
                }}
              >
                kWh
              </span>
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#2f2f2f",
                fontFamily: "myThirdFont",
                textAlign: "center",
                lineHeight: "1.2",
              }}
            >
              Energy consumed
            </Typography>
          </Box>

          {/* Time Card */}
          <Box
            sx={{
              flex: "1 1 0",
              maxWidth: "110px",
              aspectRatio: "1/1.1",
              backgroundImage: "url('/images/timeConsumedBox.png')",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography
              sx={{
                color: "#1E78FF",
                fontSize: "20px",
                fontFamily: "mySecondFont",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {calculateCompletedTime()}
              <span
                style={{
                  color: "#1E78FF",
                  fontFamily: "mySecondFont",
                  textTransform: "none",
                  fontSize: "14px",
                }}
              >
                min
              </span>
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#2f2f2f",
                fontFamily: "myThirdFont",
                textAlign: "center",
                lineHeight: "1.2",
              }}
            >
              Time
              <br /> Consumed
            </Typography>
          </Box>

          {/* Amount Card */}
          <Box
            sx={{
              flex: "1 1 0",
              maxWidth: "110px",
              aspectRatio: "1/1.1",
              backgroundImage: "url('/images/payAmountCharged.png')",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "12px",
            }}
          >
            <Typography
              sx={{
                color: "#00733D",
                fontSize: "20px",
                fontFamily: "mySecondFont",
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
            >
              ₹
              {sseData?.ocpiSession?.total_cost?.incl_vat ||
                refreshApiData?.ocpiSession?.total_cost?.incl_vat}
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#2f2f2f",
                fontFamily: "myThirdFont",
                textAlign: "center",
                lineHeight: "1.2",
              }}
            >
              Amount
              <br /> charged
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* Session Stats */}
      <Box sx={{ padding: "16px", background: "#FFF", mt: "4px" }}>
        <Box
          sx={{
            display: "flex",
            borderRadius: "8px",
            background: "linear-gradient(180deg, #E9E9E9 0%, #F8F8F8 100%)",
            padding: "16px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <DetailBox
                label="Session ID"
                value={sessionData?.ocpiSession?.id || "-"}
              />
              <DetailBox label="Start time" value={startTime} />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <DetailBox
                label="QR CODE ID"
                value={
                  sessionData?.ocpiSession?.evse_uid ||
                  sessionData?.ocpiPendingSession?.deviceId
                }
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <DetailBox
                label="Charger type"
                value={
                  refreshApiData?.ocpiPendingSession?.chargingPoint
                    ?.chargingPointType
                }
              />
              <DetailBox
                label="Connector type"
                value={
                  refreshApiData?.ocpiPendingSession?.chargingPoint
                    ?.connectorType
                }
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "32px" }}>
              <DetailBox
                label="Supply"
                value={`${refreshApiData?.ocpiPendingSession?.chargingPoint?.power}kW`}
              />
              <DetailBox
                label="Unit Cost"
                value={`₹${refreshApiData?.ocpiPendingSession?.chargingPoint?.unitCost}`}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Download */}
      <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            mt={"14px"}
            color="#292D33"
            fontFamily={"'Montserrat', sans-serif"}
            letterSpacing={"1.28px"}
            fontWeight={600}
            fontSize={"16px"}
          >
            SEARCH. SCAN. CHARGE.
          </Typography>
          <img style={{ width: "240px" }} src="/images/phone2.png" alt="" />
        </Box>
        <Box
          sx={{
            background:
              "linear-gradient(100deg, #FF7B52 -34.07%, #E50000 104.51%), #292D33",
            padding: "24px 16px",
            marginLeft: "-10px",
            marginRight: "-10px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Typography
            fontSize={"24px"}
            fontFamily={"'Montserrat', sans-serif"}
            textAlign={"center"}
            fontWeight={700}
            color="#fff"
            textTransform={"capitalize"}
          >
            Download 1C App to view
            <br /> Live Charging Session
            <br /> Details and stats
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography
              textAlign={"center"}
              color="#fcfcfc"
              fontFamily={"mySecondFont"}
              fontSize={"16px"}
            >
              Download from
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                width: "100%",
              }}
            >
              <a
                href="https://apps.apple.com/in/app/1c-ev-charging/id6478754214"
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: "100%" }}
              >
                <Button
                  sx={{
                    padding: "14px 12px",
                    background: "#090909",
                    borderRadius: "8px",
                    width: "100%",
                  }}
                >
                  <img
                    style={{ width: "126px", height: "30px" }}
                    src="/images/appstore.png"
                    alt=""
                  />
                </Button>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=in.one.charging&hl=en_IN"
                target="_blank"
                rel="noopener noreferrer"
                style={{ width: "100%" }}
              >
                <Button
                  sx={{
                    padding: "14px 12px",
                    background: "#090909",
                    width: "100%",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    style={{ width: "126px", height: "30px" }}
                    src="/images/playstore.png"
                    alt=""
                  />
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Stop button */}
      {sessionData?.ocpiSession?.status === "ACTIVE" && (
        <Box
          sx={{
            background: "#fff",
            padding: "8px 16px",
            position: "fixed",
            bottom: 12,
            left: 0,
            right: 0,
            width: "100%",
            zIndex: 1000,
            boxShadow: "0px 0px 4px 0px rgba(50, 0, 0, 0.15)",
          }}
        >
          <Button
            onClick={handleOpen}
            sx={{
              background: "#E50000",
              borderRadius: "8px",
              padding: "12px 24px",
              width: "92%",
              fontFamily: "mySecondFont",
              color: "#fcfcfc",
              letterSpacing: "1.28px",
            }}
          >
            Stop charging
          </Button>
        </Box>
      )}

      <StopChargingModal
        open={open}
        handleClose={handleClose}
        onGoingSession={onGoingSession}
      />
    </>
  );
};

export default IOCLChargingStatus;
