import ChargingStart from "../Pages/ChargingStart/ChargingStart";
import PaymentFailed from "../Pages/PaymentFailed/PaymentFailed";
import Home from "../Pages/Home/Home";
import OTPVerification from "../Pages/VerificationPage/OTPVerification";
import VerificationPage from "../Pages/VerificationPage/VerificationPage";
import WelcomePage from "../Pages/WelcomePage/WelcomePage";
import IOCLStartChargingPage from "../Pages/ChargingStart/IOCLStartChargingPage";

export const routes = [
  {
    path: "/:countryCode?/:deviceId", // Optional country code
    element: <WelcomePage />,
  },
  {
    path: "/home/:countryCode?/:deviceId", // Optional country code
    element: <Home />,
  },
  {
    path: "/verification/:countryCode?/:deviceId", // Optional country code
    element: <VerificationPage />,
  },
  {
    path: "/otp-verify/:countryCode?/:deviceId", // Optional country code
    element: <OTPVerification />,
  },
  {
    path: "/charging-start/:countryCode?/:deviceId", // Optional country code
    element: <ChargingStart />,
  },
  {
    path: "/iocl-charging-start/:countryCode?/:deviceId", // Optional country code
    element: <IOCLStartChargingPage />,
  },
  {
    path: "/payment-failed/:countryCode?/:deviceId", // Optional country code
    element: <PaymentFailed />,
  },
  {
    path: "*",
    element: "not found",
  },
];
