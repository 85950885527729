import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 342,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  outline: "none",
  borderRadius: "8px",
};

const HelpCenterModal = ({ open, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {" "}
          {/* top - heading */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <span
                style={{ fontWeight: "300" }}
                className="material-symbols-outlined"
              >
                help
              </span>
              <Typography
                fontSize={"18px"}
                color="#292D33"
                fontFamily={"mySecondFont"}
              >
                Help Center
              </Typography>
            </Box>
            <Box onClick={handleClose}>
              <span
                style={{ fontSize: "32px", fontWeight: "300", color: "#222" }}
                className="material-symbols-outlined"
              >
                cancel
              </span>
            </Box>
          </Box>
          {/* img - card */}
          <Box
            sx={{
              width: "92%",
              backgroundImage: "url('/images/call.png')",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "12px",
              mt: "16px",
            }}
          >
            <Typography
              sx={{
                color: "#FF7B52",
                fontSize: "20px",
                fontFamily: "mySecondFont",
                display: "flex",
                alignItems: "start",
                gap: "4px",
              }}
            >
              011-43146973
            </Typography>

            <Typography
              sx={{
                fontSize: "14px",
                color: "#2f2f2f",
                fontFamily: "myThirdFont",
                textAlign: "start",
                lineHeight: "1.2",
              }}
            >
              Please reach us through this number
            </Typography>
          </Box>
          {/* or - img */}
          <img
            style={{ width: "100%", margin: "12px 0px" }}
            src="/images/orimg.png"
            alt=""
          />
          {/* Buttons */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <Button
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                background: "#292D33",
                borderRadius: "8px",
                padding: "12px 16px",
                justifyContent: "flex-start",
                ":hover": {
                  background: "#292D33",
                },
              }}
            >
              <span
                style={{ color: "#fff" }}
                className="material-symbols-outlined"
              >
                support_agent
              </span>
              <Typography
                fontFamily={"mySecondFont"}
                color="#fff"
                fontSize={"16px"}
              >
                Request a callback
              </Typography>
            </Button>

            {/* whatsapp */}
            <a
              style={{ width: "100%", textDecoration: "none" }}
              href="https://wa.me/+919599443291"
              target="blank"
            >
              <Button
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  background: "#1BA031",
                  borderRadius: "8px",
                  padding: "12px 16px",
                  justifyContent: "flex-start",
                  ":hover": {
                    background: "#1BA031",
                  },
                }}
              >
                <img src="/images/whatsapp.png" alt="" />
                <Typography
                  fontFamily={"mySecondFont"}
                  color="#fff"
                  fontSize={"16px"}
                >
                  Reach us through Whatsapp
                </Typography>
              </Button>
            </a>

            {/* mail */}
            <a
              style={{ width: "100%", textDecoration: "none" }}
              href="mailto:support@1charging.com"
              target="blank"
            >
              <Button
                sx={{
                  display: "flex",
                  width: "100%",
                  gap: "4px",
                  alignItems: "center",
                  background: "#2A5FC0",
                  borderRadius: "8px",
                  padding: "12px 16px",
                  justifyContent: "flex-start",
                  ":hover": {
                    background: "#2A5FC0",
                  },
                }}
              >
                <span
                  style={{ color: "#fff" }}
                  className="material-symbols-outlined"
                >
                  mail
                </span>
                <Typography
                  fontFamily={"mySecondFont"}
                  color="#fff"
                  fontSize={"16px"}
                >
                  Reach us through Email
                </Typography>
              </Button>
            </a>
            {/* or - img */}
            <img
              style={{ width: "100%", margin: "12px 0px" }}
              src="/images/orimg.png"
              alt=""
            />

            {/* faq */}
            <Button
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                border: "1px solid #C1C1C1",
                borderRadius: "8px",
                padding: "12px 16px",
                mt: "8px",
                justifyContent: "flex-start",
                boxShadow: "0px 2px 4px 0px rgba(34, 34, 34, 0.25)",
              }}
            >
              <span
                style={{ color: "#222" }}
                className="material-symbols-outlined"
              >
                contact_support
              </span>
              <Typography
                fontFamily={"mySecondFont"}
                color="#222"
                fontSize={"16px"}
              >
                Frequently asked questions
              </Typography>
            </Button>

            {/* logo */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                style={{ width: "124px" }}
                src="/images/directlogo.png"
                alt=""
              />
            </Box>

            {/* close button */}
            <Button
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                border: "1px solid #C1C1C1",
                borderRadius: "8px",
                padding: "12px 16px",
                mt: "8px",
              }}
              onClick={handleClose}
            >
              <Typography
                fontFamily={"mySecondFont"}
                color="#222"
                fontSize={"16px"}
              >
                Close
              </Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default HelpCenterModal;
