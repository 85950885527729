import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ConfirmAmountModal from "../Modals/ConfirmAmountModal";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  fetchDeviceDetails,
  fetchOnGoingSession,
  fetchOutstandingAmount,
  makePayment,
  makePaymentV2,
} from "../../redux/action/directPaymentSlice";
import { toast } from "sonner";
import { countryToCurrency } from "../../utils/countryCodes";
import { currencySymbols } from "../../utils/currencySymbols";
import Checkbox from "@mui/material/Checkbox";
import DueAmountModal from "../Modals/DueAmountModal";
import SessionDetailsModal from "../Modals/SessionDetailsModal";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const values = ["50", "75", "100", "150"];
const nzValues = ["1", "5", "10", "15", "20"];

const IOCLHomePage = () => {
  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  //   duemodal
  const [openDueModal, setOpenDueModal] = React.useState(false);
  const handleOpenDueModal = () => setOpenDueModal(true);
  const handleCloseDueModal = () => setOpenDueModal(false);

  //   session modal
  const [openSessionModal, setOpenSessionModal] = React.useState(false);
  const handleOpenSessionModal = () => setOpenSessionModal(true);
  const handleCloseSessionModal = () => setOpenSessionModal(false);

  const { deviceId, countryCode } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { failedPayment, newAmount, retryTriggered, skipRetry } =
    location.state || {};
  const [loading, setLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);

  const phoneNumber = location.state?.phoneNumber || "";

  // Extract the country code from the URL
  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2); // Assuming country codes are 2 letters
  const currency = countryToCurrency[countryCodeName] || "INR"; // Default to INR if not found
  const currencySymbol = currencySymbols[currency] || "₹"; // Default to ₹

  const amounts = currency === "NZD" ? nzValues : values;

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleChipClick = (value) => {
    setAmount(value ? Number(value) : "");
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setAmount(value ? Number(value) : "");
  };

  const deviceDetails = useSelector(
    (state) => state.directPayment.deviceDetails
  );

  const outStandingAmount = useSelector(
    (state) => state.directPayment.outstandingAmount
  );

  useEffect(() => {
    if (deviceId) {
      dispatch(fetchDeviceDetails(deviceId));
    }
  }, [deviceId, dispatch]);

  const verifyOtpResponse = useSelector(
    (state) => state.directPayment.verifyOtpResponse
  );

  const saveToken = (token) => {
    const expiryTime = new Date().getTime() + 10 * 60 * 1000;
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpiry", expiryTime);
  };

  useEffect(() => {
    if (verifyOtpResponse?.token) {
      saveToken(verifyOtpResponse.token);
    }
  }, [verifyOtpResponse]);

  const handleProceed = () => {
    let minAmount;

    if (currency === "NZD") {
      minAmount = 0.5;
    } else if (currency === "LKR") {
      minAmount = 10;
    } else {
      minAmount = 20;
    }

    if (amount < minAmount) {
      toast.error(
        `Please enter a minimum amount of ${
          currency === "NZD"
            ? "NZD 0.50"
            : currency === "LKR"
            ? "LKR 10"
            : "₹20"
        }`
      );
    } else {
      handleOpen();
    }
  };

  const handleTax = () => {
    toast.info("Including Tax!");
  };

  useEffect(() => {
    if (retryTriggered && failedPayment === true && newAmount && !skipRetry) {
      setRetryLoading(true);

      dispatch(makePayment({ amount: newAmount, deviceId }))
        .then((res) => {
          if (res.error) {
            toast.error("Payment retry failed. Please try again.");
            setRetryLoading(false);
          } else {
            toast.success("Redirecting to Paytm...");
          }
        })
        .catch(() => {
          toast.error("Payment retry encountered an error.");
          setRetryLoading(false);
        });
    }
  }, [retryTriggered, failedPayment, newAmount, dispatch, deviceId, skipRetry]);

  const handleStartCharging = async () => {
    if (dueAmount === true) {
      handleOpenDueModal(true);
    } else {
      setRetryLoading(true); // Show the full-page loader
      try {
        const response = await dispatch(
          makePaymentV2({ amount, deviceId })
        ).unwrap(); // Unwraps the promise to access the payload or error

        setRetryLoading(false); // Hide the loader after the API call

        if (response.statusCode === 400) {
          // Show error message from the API in a toast
          toast.error(
            response.message ||
              "An error occurred while starting the charging process."
          );
        } else {
          // Navigate if the API call was successful
          navigate(
            `/iocl-charging-start/${countryCode}/${deviceId}?deviceId=${deviceId}&iocl=true`
          );
          // const response = await dispatch(
          //   makePaymentV2({ amount, deviceId })
          // ).unwrap();
        }
      } catch (error) {
        setRetryLoading(false); // Hide the loader in case of error
        toast.error("Something went wrong. Please try again.");
      }
    }
  };

  useEffect(() => {
    dispatch(fetchOutstandingAmount());
    dispatch(fetchOnGoingSession());
  }, [dispatch]);

  const dueAmount = outStandingAmount?.outstandingAmount > 0 ? true : false;

  return (
    <>
      {retryLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(255, 255, 255, 0.7)",
            zIndex: 9999,
          }}
        >
          <CircularProgress size={50} sx={{ color: "#FF7B52" }} />
        </Box>
      )}
      <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "6px",
          }}
        >
          <Typography
            color="#161616"
            fontSize={"14px"}
            fontFamily={"mySecondFont"}
            textAlign={"center"}
          >
            {deviceDetails?.deviceId || "N/A"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              {/* <img src="/images/ctype.png" alt="" /> */}
              <Typography
                color="#1C1C1C"
                fontFamily={"mySecondFont"}
                fontSize={"16px"}
              >
                {deviceDetails?.connectorType || "N/A"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                color="#2F2F2F"
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
              >
                Supply:
              </Typography>
              <Typography
                color="#1C1C1C"
                fontFamily={"mySecondFont"}
                fontSize={"16px"}
              >
                {`${deviceDetails?.power || "N/A"} kW`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography
                color="#2F2F2F"
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
              >
                Unit Cost:
              </Typography>
              <Typography
                color="#1C1C1C"
                fontFamily={"mySecondFont"}
                fontSize={"16px"}
                sx={{ display: "flex", alignItems: "center" }}
              >
                {`${currencySymbol}${deviceDetails?.unitCost || "N/A"}/kWh`}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: 300,
                    marginLeft: "4px",
                  }}
                  className="material-symbols-outlined"
                  onClick={handleTax}
                >
                  info
                </span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="page" sx={{ mt: "4px", background: "#fff" }}>
        <Box sx={{ padding: "6px" }}>
          <Typography
            textAlign={"center"}
            color={"#1C1C1C"}
            fontFamily={"'Montserrat', sans-serif"}
            fontWeight={600}
            fontSize={"18px"}
          >
            Please add charging amount
          </Typography>

          {/* Input */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              mt: "16px",
            }}
          >
            <Typography
              color="#1C1C1C"
              fontFamily={"mySecondFont"}
              fontSize={"16px"}
            >
              For how much amount do you want to charge your
              <br /> EV for?
            </Typography>
            <input
              style={{
                padding: "0px 16px",
                borderRadius: "8px",
                border: "1px solid #cbcbcb",
                height: "48px",
                outline: "none",
                fontFamily: "myThirdFont",
              }}
              placeholder="Please type in the amount here"
              type="number"
              value={amount} // Bind input value to state
              onChange={handleInputChange} // Handle input change
            />

            {/* value chips */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                mt: "12px",
                flexWrap: "wrap",
              }}
            >
              {amounts?.map((item, index) => {
                return (
                  <Box
                    key={index}
                    onClick={() => handleChipClick(item)}
                    sx={{
                      padding: "0 16px",
                      borderRadius: "40px",
                      background:
                        "linear-gradient(94deg, rgba(255, 123, 82, 0.15) -20.03%, rgba(229, 0, 0, 0.15) 122.12%)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      flexWrap: "nowrap",
                      height: "48px",
                      flexShrink: 0,
                    }}
                  >
                    <Typography
                      color="#000"
                      fontSize={"16px"}
                      fontFamily={"mySecondFont"}
                    >
                      {`${currency} ${item}`} {/* Use currency directly */}
                    </Typography>
                  </Box>
                );
              })}
            </Box>

            {/* Due Check */}
            {dueAmount === true ? (
              <Box
                sx={{
                  padding: "12px",
                  borderRadius: "8px",
                  borderLeft: "2px solid #790000",
                  background: "rgba(121, 0, 0, 0.10)",
                  display: "flex",
                  gap: "8px",
                  alignItems: "flex-start",
                  mt: "16px",
                }}
              >
                <span
                  style={{
                    color: "#790000",
                    fontWeight: "300",
                    width: "20px",
                    height: "20px",
                  }}
                  className="material-symbols-outlined"
                >
                  info
                </span>
                <Typography fontFamily={"myThirdFont"} color={"#790000"}>
                  <span
                    style={{
                      fontFamily: "mySecondFont",
                    }}
                  >
                    ₹{outStandingAmount?.outstandingAmount?.toFixed(2)}{" "}
                  </span>
                  {`will be added to your final amount due to outstanding amount
                from previous session`}{" "}
                  <span
                    onClick={handleOpenSessionModal}
                    style={{
                      textDecoration: "underline",
                      fontStyle: "italic",
                      fontFamily: "myThirdFont",
                    }}
                  >
                    {outStandingAmount?.outstandingSessions[0]?.ocpiSession?.id}
                  </span>
                </Typography>
              </Box>
            ) : (
              ""
            )}

            {/* new added */}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "4px",
                mt: "16px",
              }}
            >
              <Checkbox
                sx={{
                  width: "24px",
                  height: "24px",
                  "&.Mui-checked": {
                    color: "#e50000",
                  },
                  "&:hover": {
                    backgroundColor: "rgba(229, 0, 0, 0.04)",
                  },
                }}
                {...label}
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <Typography
                color={"#2F2F2F"}
                fontFamily={"myThirdFont"}
                fontSize={"14px"}
              >
                I accept responsibility for any payment faults on my end and
                agree not to blame the service operator.
              </Typography>
            </Box>

            {/* Note and Button */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                mt: "12px",
                mb: "100px",
              }}
            >
              <Button
                sx={{
                  background: isChecked
                    ? "linear-gradient(94deg, #FF7B52 -20.04%, #E50000 122.31%)"
                    : "#cccccc",
                  borderRadius: "8px",
                  padding: "12px 24px",
                  width: "100%",
                  fontFamily: "mySecondFont",
                  color: "#fcfcfc",
                  letterSpacing: "1.28px",
                  mt: "16px",
                }}
                onClick={handleStartCharging}
                disabled={!isChecked}
              >
                Start Charging
              </Button>
              <Typography color="#727272" fontFamily={"myThirdFont"}>
                <span style={{ fontFamily: "mySecondFont" }}>Note:</span> The
                extra amount will be refunded back into payment source.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <ConfirmAmountModal
        open={open}
        handleClose={handleClose}
        amount={amount}
        deviceId={deviceId}
        countryCode={countryCode}
        phoneNumber={phoneNumber}
        deviceDetails={deviceDetails}
        setRetryLoading={setRetryLoading}
      />

      {/* dueamount - modal */}
      {dueAmount && (
        <DueAmountModal
          open={openDueModal}
          handleClose={handleCloseDueModal}
          amount={amount}
          deviceId={deviceId}
          outStandingAmount={outStandingAmount?.outstandingAmount}
          countryCode={countryCode}
          setRetryLoading={setRetryLoading}
        />
      )}

      {/* session - modal */}
      <SessionDetailsModal
        open={openSessionModal}
        handleClose={handleCloseSessionModal}
        allData={outStandingAmount}
      />
    </>
  );
};

export default IOCLHomePage;
