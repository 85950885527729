import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider } from "@mui/material";
import { stopSession } from "../../redux/action/directPaymentSlice";
import { useDispatch } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 330,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  outline: "none",
  borderRadius: "8px",
};

const StopChargingModal = ({ open, handleClose, onGoingSession }) => {
  const dispatch = useDispatch();

  const handleStop = () => {
    // To stop the session
    dispatch(stopSession(onGoingSession?.session?.uid))
      .unwrap()
      .then((response) => {
        // Handle success
        handleClose();
        console.log("Session stopped successfully:", response);
      })
      .catch((error) => {
        // Handle error
        console.error("Failed to stop session:", error);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "48px",
                height: "48px",
                borderRadius: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(41, 45, 51, 0.08)",
              }}
            >
              <span
                style={{ fontWeight: 300 }}
                className="material-symbols-outlined"
              >
                info
              </span>
            </Box>
            <Typography
              fontSize={"18px"}
              fontFamily={"mySecondFont"}
              color="#262626"
              textAlign={"center"}
            >
              Are you sure you want to stop the charging?
            </Typography>

            {/* Buttons */}
            <Box sx={{ display: "flex", gap: "8px", width: "100%", mt: "8px" }}>
              <Button
                onClick={handleClose}
                sx={{
                  background: "transparent",
                  borderRadius: "8px",
                  padding: "8px 24px",
                  width: "50%",
                  fontFamily: "mySecondFont",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#2F2F2F",
                  border: "1px solid rgba(41, 45, 51, 0.2)",
                  "&:hover": {
                    backgroundColor: "#F2F2F2",
                  },
                }}
              >
                NO
              </Button>
              <Button
                onClick={handleStop}
                sx={{
                  background:
                    "linear-gradient(96deg, #FF7B52 11.82%, #E50000 92.52%)",
                  borderRadius: "8px",
                  padding: "8px 24px",
                  width: "50%",
                  fontFamily: "mySecondFont",
                  fontSize: "12px",
                  fontWeight: 700,
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#F2F2F2",
                  },
                }}
              >
                YES
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default StopChargingModal;
